body {
  background: #fdfdfd;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

/** custom classes */

.notunder1500 {
  .btn {
    width: 100%;
    border-radius: 0!important;
  }
}

.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 66vw;

  @include media-breakpoint-up(xl) {
    min-height: 450px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

// Images

img {
  max-width: 100%;
}

// Util
.img-invert-white {
  filter: brightness(0) invert(1);
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}
.z-index-2 {
  z-index:2;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 160px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}

#menu-item-61313:hover{
  color:#fff !important
}


.glass {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
 background: $brand-secondary;
 border-radius: 30px;
}



.primary-border{
border: 4px solid $brand-primary;
}



.footround{
  border-radius: 0px 0px 24px 24px !important;
  background: $brand-primary;
  color: #fff;
}


.card{
  
    
    .footround{
      background: $brand-secondary;
      color: $brand-primary;
    }
    .primary-border{
      border: 4px solid $brand-secondary;
      }
    
  }

  .rpb-cards{
    
  &:hover{
    background-size: 200%;
    transition: 0.5s ease;
  
  }
}




.extraround{
  border-radius:100px;
}

/*
.ginput_container{
  background: $brand-secondary;
  border: 4px solid $brand-primary;
  border-radius: 30px;
}

#input_1{
  border-radius:30px;
}

.gform_legacy_markup_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_legacy_markup_wrapper textarea.large, .gform_legacy_markup_wrapper select.large {
  background: none !important;
  color: #fff !important;
  border: 0px !important;
}*/

.lessround{
  border-radius: 26px;
  z-index: -1;
}

#menu-item-61643{
  .header-default .navbar-nav .nav-link{
    color: white !important;
  }
}

.gform_legacy_markup_wrapper form{
  padding-left: 1rem;
}


.review-btn{
  min-width: 275px;
  max-width: 275px;
}


.gradient-blue{
  background:linear-gradient(rgba(3,44,102,0.65), rgba(5,30,80,0.65));
}

.image-zoom {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1.5s, filter 1.5s ease-in-out;
  overflow: hidden;
  background: linear-gradient(rgba(0,0,0,0.6));
  filter: brightness(80%);


}

.card:hover .image-zoom {
  transform: scale(1.2);
  overflow: hidden;
  filter: brightness(105%);
}
.wrapper {
    position: relative;
    overflow: hidden;
  }