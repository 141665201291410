// Theme Colors/Branding
$brand-primary: 	#f69435;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #4793FF;
$dark: #1475ff;
$light: #fdfdfd;
$medium: #0e169b;
$inactive: #8c8c8c;
$gray: #000f3f;
$white: #fff;
$accent: #1f8cf5;
$accent-2: #052ac6;
$body-font: #231f20;
$error: #d81e00;